@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

/* BODY AND OVERALL CSS */

:root {
  --blue: blue;
  --white: white;
}

body.light {
  --background-color: white;
  --navbar-bg-color: rgba(244, 244, 244, 0.85);
  --text-color: black;
  --stroke-color: black;
  --post-link: #0052cc;
  --navlogo: url('../public/newestlightlogo.png');
  --gradient-text: -webkit-linear-gradient(135deg, #FA0177, #7621BF);
}

body.dark {
  --background-color: #0f182a;
  --navbar-bg-color: rgba(24, 33, 50, 0.85);
  --text-color: white;
  --stroke-color: #ffe665;
  --post-link: #4bc0e0;
  --navlogo: url('../public/newestdarklogo.png');
  --gradient-text: -webkit-linear-gradient(135deg, #FF4D41, #F8C025, #FF4D41);
}

#fillparallaxbg {
  background: var(--background-color);
}

#button {
  box-shadow: 4px 4px 3px #3c3c3c;
  background-color: #ffe665;
  color: black;
  font-weight: bold;
  border: none;
  transition: all 0.4s;
  transition-timing-function:cubic-bezier(0.5, 3, 0, 1);
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 20px; 
  margin-bottom: 20px;
}

#partnerbutton {
    box-shadow: 4px 4px 3px #3c3c3c;
    background-color: #ffe665;
    color: black;
    font-weight: bold;
    border: none;
    transition: all 0.4s;
    transition-timing-function:cubic-bezier(0.5, 3, 0, 1);
}

#button:hover {
  transform: scale(1.1);
  box-shadow: 8px 8px 6px #3c3c3c;
  background-color: #ffe665;
}

#container {
  background: var(--background-color);
  color: var(--text-color);
  font-family: 'Montserrat', sans-serif;
  padding-top: 12vh;
  padding-bottom: 25vh;
  min-height: 100vh;
  padding-right: 3.5vw;
  padding-left: 3vw;
  position: relative;
  background-size: cover;
}
#studentcontainer {
  background: var(--background-color);
  color: var(--text-color);
  font-family: 'Montserrat', sans-serif;
  padding-top: 12vh;
  padding-bottom: 25vh;
  min-height: 100vh;
  padding-right: 3.5vw;
  padding-left: 3vw;
  position: relative;
  background-size: cover;
}
@media screen and (max-width: 1025px) {
  #studentcontainer {
    padding-left: 1px;
  }
}

#shadowy {
  box-shadow: 8px 8px 6px #3c3c3c;
  /* position relative enables me to bottom align the 'last updated' text */
  position: relative;
  background-color: var(--background-color);
  color: var(--text-color);
  border-color: var(--text-color);
  cursor: pointer;
}

#divmesomespace {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

#divmesomespacebig {
  padding-top: 15vh;
  padding-bottom: 15vh;
}
@media screen and (min-width: 769px) {
  #divmesomespacebig {
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
}
@media screen and (max-width: 450px) {
  #divmesomespacebig {
    padding-top: 20vh;
    padding-bottom: 20vh;
  }
}

#cardtitlefont {
  font-weight: 700;
  text-decoration: none;
  color: var(--text-color);
}

#cardtextfont {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: var(--text-color);
  font-size: 0.7rem;
/* bottom aligns the text with 3px padding */
  position: absolute;
  bottom: 3px;
}

#textchunk {
  padding-left: 15vw;
  padding-right: 15vw;
  line-height: 2;
}
@media screen and (max-width: 500px) {
  #textchunk {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}


/* NAVBAR CSS */

#nav {
  background: var(--navbar-bg-color);
  padding-top: 0px;
  padding-bottom: 0px;
  backdrop-filter: blur(10px);
}

#navdropdownitem {
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  transition: background 0.5s ease-in-out;
  color: black;
}

#navlinkoverridetextcolor {
  font-family: 'Montserrat', sans-serif;
  margin-top: 8px;
  color: var(--text-color);
}

@media screen and (min-width: 991px) {
  .underline2 {
    transition: .5s;
    position: relative;
    padding: 0 2px;
  }
  .underline2:after {
    content: "";
    position: absolute;
    background-color: var(--stroke-color);
    height: 3px;
    width: 0;
    left: 0;
    bottom: -8px;
    transition: 0.3s;
  }
  .underline2:hover:after {
    width: 100%;
  }
  }

@media screen and (min-width: 991px) {
.underline {
  transition: .5s;
  position: relative;
  padding: 0 2px;
}
.underline:after {
  content: "";
  position: absolute;
  background-color: var(--stroke-color);
  height: 3px;
  width: 0;
  left: 0;
  bottom: -8px;
  transition: 0.3s;
}
.underline:hover:after {
  width: 100%;
}
}

#landinganimation{
  display: grid;
  place-items: center;
}

#nogrow{
  flex-grow: 0;
}

#hometextanimation {
  padding-top: 3vh;
  width: max(40vw, 900px);
  height: auto;
  stroke: var(--text-color);
  animation: fill 1s ease forwards 4.5s;
}
@media screen and (max-width: 991px) {
  #hometextanimation {
  padding-top: 15vh;
  width: 90vw;
  height: auto;
  }
}

#hometextanimation path:nth-child(1) {
  stroke-dasharray: 508px;
  stroke-dashoffset: 508px;
  animation: line-anim 2s ease forwards;
}
#hometextanimation path:nth-child(2){
  stroke-dasharray: 381px;
  stroke-dashoffset: 381px;
  animation: line-anim 2s ease forwards 0.3s;
}
#hometextanimation path:nth-child(3){
  stroke-dasharray: 385px;
  stroke-dashoffset: 385px;
  animation: line-anim 2s ease forwards 0.6s;
}
#hometextanimation path:nth-child(4){
  stroke-dasharray: 553px;
  stroke-dashoffset: 553px;
  animation: line-anim 2s ease forwards 0.9s;
}
#hometextanimation path:nth-child(5){
  stroke-dasharray: 617px;
  stroke-dashoffset: 617px;
  animation: line-anim 2s ease forwards 1.2s;
}
#hometextanimation path:nth-child(6){
  stroke-dasharray: 538px;
  stroke-dashoffset: 538px;
  animation: line-anim 2s ease forwards 1.5s;
}
#hometextanimation path:nth-child(7){
  stroke-dasharray: 485px;
  stroke-dashoffset: 485px;
  animation: line-anim 2s ease forwards 1.8s;
}
#hometextanimation path:nth-child(8){
  stroke-dasharray: 515px;
  stroke-dashoffset: 515px;
  animation: line-anim 2s ease forwards 2.1s;
}
#hometextanimation path:nth-child(9){
  stroke-dasharray: 544px;
  stroke-dashoffset: 544px;
  animation: line-anim 2s ease forwards 2.4s;
}
#hometextanimation path:nth-child(10){
  stroke-dasharray: 552px;
  stroke-dashoffset: 552px;
  animation: line-anim 2s ease forwards 2.7s;
}
@keyframes line-anim{
  to{
    stroke-dashoffset: 0;

  }
}
@keyframes fill{
  from{
    fill: transparent;
  }
  to{
    fill: var(--text-color);
  }
}

#homegraphic {
  width: max(40vw, 900px);
  height: auto;
}
@media screen and (max-width: 991px) {
  #homegraphic {
  width: 90vw;
  height: auto;
  }
}
#sticky2{
  opacity: 0;
  animation: fadeInUp .8s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}
#sticky3{
  opacity: 0;
  animation: fadeInUp .8s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}
#sticky4{
  opacity: 0;
  animation: fadeInUp .8s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(40%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

#girl{
  animation: fadeInRight 2s;
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-40%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
#guy{
  animation: fadeInLeft 2s;
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(40%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
#phonegroup{
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 4s;
  animation-fill-mode: forwards;
}
#step1group{
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}
#num2{
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}
#step3group {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 2.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#athcs{
  opacity: 0;
  padding-top: 2vh;
  font-weight: 400;
  animation: fadeIn 3s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}

#navbartoggle {
  background: white;
}

#homelogo {
  width: 125px;
  min-height: 60px;
  cursor: pointer;
  background-image: var(--navlogo);
  background-size: cover;
  transition: transform .7s ease-in-out;
}
#homelogo:hover {
    transform: rotate(360deg);
}
#productlabbrand {
  width: 125px;
  min-height: 60px;
}

#navlinkalign {
  margin-top: 8px;
}
#bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}


/* HOME PAGE CSS */

#blogtitlefont {
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: var(--background-color);
}

#homesection {
  padding-top: 15vh;
  padding-bottom: 5vh;
}

#homepushdown {
  padding-top: 17vh;
}

#homep {
  padding-left: 20vw;
  padding-right: 20vw;
  line-height: 2;
}
@media screen and (max-width: 650px) {
  #homep {
    padding-left: 1vw;
    padding-right: 1vw;
  }
}

#limitwidth {
  overflow: hidden;
}

#gradienttext {
  font-size: 5vw;
  background: var(--gradient-text);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 600px) {
  #gradienttext {
    font-size: 10vw;
  }
}
#gradienttextsub {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

#gradienttext3 {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

#sponsor {
  display: grid;
  place-items: center;
}
#sponsor:hover {
  transform: scale(1.1);
  transition: 0.3s;
}
#rocketblocks {
  width: 293px;
  height: auto;
}

/* ABOUT PAGE CSS */

#aboutcard {
  /* next line is what makes all the blog cards the same height */
    min-height: max(40vh, 400px);
    margin-inline: auto;
    width: 22vw;
    transition: all 0.4s;
    transition-timing-function:cubic-bezier(0.5, 3, 0, 1);
    background-color: var(--background-color);
    color: var(--text-color);
    border-color: var(--text-color);
    position: relative;
    }
  #aboutcard:hover {
    transform: scale(1.1);
    box-shadow: 8px 8px 6px #3c3c3c;
}
@media screen and (max-width: 1024px) {
  #aboutcard:hover {
    transform: none;
    box-shadow: none;
  }
}

#overridefont {
  font-family: 'Montserrat', sans-serif;
}

#accordian {
  position: absolute;
  background-color: var(--background-color);
  color: var(--text-color);
  border-color: var(--text-color);
  bottom: 0px;
  width: fill;
}

#expandmoreicon{
  color: var(--text-color);
}

#advisorcard {
    transition: all 0.4s;
    margin-inline: auto;
    transition-timing-function:cubic-bezier(0.5, 3, 0, 1);
    background-color: var(--background-color);
    color: var(--text-color);
    border-color: var(--text-color);
    }
  #advisorcard:hover {
    transform: scale(1.1);
    box-shadow: 8px 8px 6px #3c3c3c;
}
@media screen and (max-width: 1024px) {
  #advisorcard:hover {
    transform: none;
    box-shadow: none;
  }
}




/* CLIENT PAGE CSS */

#forbigscreenonly{
  text-decoration: none;
}
@media screen and (max-width: 1310px) {
  #forbigscreenonly {
    display: none;
  }
}

@media screen and (min-width: 1310px) {
  #forsmallscreenonly {
    display: none;
  }
}

#clientp {
  width: 75vw;
  left: 50%;
  transform: translate(12.5%, 0);
  padding-left: 10vw;
  padding-right: 10vw;
  line-height: 2;
}
@media screen and (max-width: 500px) {
  #clientp {
    padding-left: 1vw;
    padding-right: 1vw;
  }
}


@media screen and (min-width: 1311px) {
  #clientpmobile {
    display: none;
  }
}

#marginz {
  padding-left: 40px;
  padding-right: 40px;
}
@media screen and (min-width: 690px) {
  #marginz {
    padding-left: 25vw;
    padding-right: 25vw;
  }
}
#divmedynamicspace {
  padding-top: 3vw;
  padding-bottom: 3vw;
}
@media screen and (max-width: 830px) {
  #divmedynamicspace {
    padding-top: 1vw;
    padding-bottom: 1vw;
  }
}
@media screen and (max-width: 670px) {
  #divmedynamicspace {
    padding-top: 0vw;
    padding-bottom: 0vw;
  }
}

#line {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 1300px;
  height: auto;
  stroke: var(--text-color);
}
@media screen and (max-width: 1310px) {
  #line {
    display: none;
  }
}
#mobilesteps {
  text-decoration: none;
  display: grid;
  place-items: center;
}
@media screen and (min-width: 1311px) {
  #mobilesteps {
    display: none;
  }
}
#path {
  stroke: var(--stroke-color);
}

#line2 {
  position: absolute;
  width: 50px;
  height: auto;
  stroke: var(--text-color);
}
@media screen and (min-width: 1311px) {
  #line2 {
    display: none;
  }
}
#line3 {
  position: absolute;
  width: 50px;
  height: auto;
  stroke: var(--text-color);
}
@media screen and (min-width: 1311px) {
  #line3 {
    display: none;
  }
}
.leftline {
  left: 5vw;
}
@media screen and (max-width: 700px) {
  .leftline {
    left: 20px;
  }
}
@media screen and (max-width: 500px) {
  .leftline {
    left: 4px;
  }
}
.rightline {
  right: 5vw;
}
@media screen and (max-width: 700px) {
  .rightline {
    right: 20px;
  }
}
@media screen and (max-width: 500px) {
  .rightline {
    right: 4px;
  }
}

#path2 {
  stroke: var(--stroke-color);
}
#path3 {
  stroke: var(--stroke-color);
}

#firstmobilestep {
  margin-top: 8vh;
  padding: 10px;
  text-align: left;
  width: min(800px, 70vw);
  transform: translate(10%, 0);
  border: 2px solid #ffd500;
  border-radius: 25px;
}
#mobilestep {
  margin-top: 12vh;
  padding: 10px;
  text-align: left;
  width: min(800px, 70vw);
  transform: translate(10%, 0);
  border: 2px solid #ffd500;
  border-radius: 25px;
}
#uvps {
  margin-top: 210rem;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 1310px) {
  #uvps {
    margin-top: 0rem;
  }
}

#uvpicon {
  width: max(10vw, 300px);
  height: auto;
}

#stars {
  height: max(20vh, 150px);
  width: auto;
}

#pad {
  padding-left: 15vw;
  padding-right: 15vw;
}
@media screen and (max-width: 500px) {
  #pad {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

#clientanimation {
  border: 0px;
}

/* STUDENTS PAGE CSS */

#studentanimation {
  display: grid;
  place-items: center;
}

#studentgraphic {
  width: max(20vw, 400px);
  height: auto;
}
@media screen and (max-width: 991px) {
  #studentgraphic {
  width: 90vw;
  height: auto;
  }
}
#leftguy {
  opacity: 0;
  animation: fadeInRight 1.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}
#leftgirl {
  opacity: 0;
  animation: fadeInRight 1.5s;
  animation-delay: 0.25s;
  animation-fill-mode: forwards;
}
#rightguy {
  opacity: 0;
  animation: fadeInLeft 1.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
#rightgirl {
  opacity: 0;
  animation: fadeInLeft 1.5s;
  animation-delay: 0.75s;
  animation-fill-mode: forwards;
}

#studentspics {
  width: max(30vw, 325px);
  height: auto;
  box-shadow: 8px 8px 6px #3c3c3c;
}
#centereddiv {
  display: grid;
  place-items: center;
}

@media screen and (max-width: 767px) {
  #centereddiv2 {
    position: absolute;
    left: 45%;
    transform: translateX(-50%);
  }
}

#padblog {
 padding-bottom: 10vh;
}
@media screen and (max-width: 767.5px) {
  #padblog {
    padding-bottom: 30vh;
  }
}
@media screen and (max-width: 430px) {
  #padblog {
    padding-bottom: 35vh;
  }
}
@media screen and (max-width: 380px) {
  #padblog {
    padding-bottom: 40vh;
  }
}
@media screen and (max-width: 350px) {
  #padblog {
    padding-bottom: 60vh;
  }
}

#padpastevents {
  padding-bottom: 10vh;
 }
 @media screen and (max-width: 767.5px) {
   #padpastevents {
     padding-bottom: 30vh;
   }
 }
 @media screen and (max-width: 430px) {
   #padpastevents {
     padding-bottom: 25vh;
   }
 }

#studentsitemtext {
  line-height: 2;
}

#bigbutton {
  box-shadow: 4px 4px 3px #3c3c3c;
  width: 200px;
  height: 50px;
  padding-top: 15px;
  background-color: #ffe665;
  border-radius: 25px;
  color: black;
  font-weight: bold;
  border: none;
  transition: all 0.4s;
  transition-timing-function:cubic-bezier(0.5, 3, 0, 1);
}
#bigbutton:hover {
  transform: scale(1.1);
  box-shadow: 8px 8px 6px #3c3c3c;
  background-color: #ffe665;
}

#simpleshadow:hover {
  transition: all 0.4s;
  transition-timing-function: cubic-bezier(0.5, 3, 0, 1);
  transform: scale(1.3);
}
@media screen and (max-width: 1024px) {
  #simpleshadow:hover {
    transform: none;
  }
}

#centertextchunk {
  text-align: center;
  padding-left: 22vw;
  padding-right: 22vw;
  line-height: 2;
}
@media screen and (max-width: 500px) {
  #centertextchunk {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}


/* EVENTS PAGE CSS */

#pasteventfade {
  opacity: 0;
  animation: fadeInUp 1s;
  animation-delay: 1.3s;
  animation-fill-mode: forwards;
}

#upcomingeventscard {
  /* next line is what makes all the blog cards the same height */
    min-height: fit-content;
    max-width: max(400px, 25vw);
    transition: all 0.4s;
    background-color: var(--background-color);
    color: var(--text-color);
    border-color: var(--text-color);
    transition-timing-function: cubic-bezier(0.5, 3, 0, 1);
    }
  #upcomingeventscard:hover {
    transform: scale(1.1);
    box-shadow: 8px 8px 6px #3c3c3c;
}
@media screen and (max-width: 1024px) {
  #upcomingeventscard:hover {
    transform: none;
    box-shadow: none;
  }
}

.bold {
  font-weight: 700;
}

#upcomingeventsphoto {
  max-width: min(400px, 25vw);
  margin-inline: auto;
}
#center {
  text-align: center;
}
.center {
  text-align: center;
}
.right {
  text-align: end;
}

#pasteventscard {
  /* next line is what makes all the blog cards the same height */
    min-height: max(40vh, 450px);
    transition: all 0.4s;
    margin-inline: auto;
    background-color: var(--background-color);
    color: var(--text-color);
    border-color: var(--text-color);
    transition-timing-function:cubic-bezier(0.5, 3, 0, 1);
    }
  #pasteventscard:hover {
    transform: scale(1.1);
    box-shadow: 8px 8px 6px #3c3c3c;
}
@media screen and (max-width: 1024px) {
  #pasteventscard:hover {
    transform: none;
    box-shadow: none;
  }
}

#eventaccordian {
  position: absolute;
  min-width: inherit;
  bottom: 0px;
  background-color: var(--background-color);
  color: var(--text-color);
  border-color: var(--text-color);
}

#eventdescription {
  padding-top: 10px;
  font-family: 'Montserrat', sans-serif;
}


/* POST PAGE CSS */

#postbody a
  {
      color: var(--post-link);
  }
#postbody img
  {
      max-width: min(56vw, 600px);
      height: auto;
  }
  @media screen and (max-width: 1000px) {
    #postbody img {
      max-width: min(84vw, 600px);
    }
  }
#postbody figure
  {
      max-width: min(56vw, 600px);
      height: auto;
  }
  @media screen and (max-width: 1000px) {
    #postbody figure {
      max-width: min(84vw, 600px);
    }
}



#postcontainer {
  background: var(--background-color);
  color: var(--text-color);
  font-family: 'Montserrat', sans-serif;
  align-content: center;
  padding-top: 9vh;
  padding-bottom: 25vh;
  padding-left: 22vw;
  padding-right: 22vw;
  min-height: 100vh;
  height: fit-content;
  width: fit-content;
  width: 100vw;
  background-size: cover;
  position: relative;
}
@media screen and (max-width: 1000px) {
  #postcontainer {
    padding-left: 8vw;
    padding-right: 8vw;
  }
}

#italic {
  font-style: italic;
}

.postbody {
  padding-top: 30px;
  font-weight: 400;
  line-height: 2;
  width: 100%;
}

/* FAQS PAGE CSS */

#divmesomespacesmall {
  /* these 2 lines stop cards from overlapping. also the g-5 in the row tag of index and content  */
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

#faqcard {
    transition: transform 0.4s;
    margin-inline: auto;
    transition-timing-function: cubic-bezier(0.5, 3, 0, 1);
    background-color: var(--background-color);
    color: var(--text-color);
    border-color: var(--text-color);
  }
#faqcard:hover {
    transform: scale(1.1);
    box-shadow: 8px 8px 6px #3c3c3c;
}
@media screen and (max-width: 1024px) {
  #faqcard:hover {
    transform: none;
    box-shadow: none;
  }
}

#question {
  opacity: 0;
  animation: fadeInLeft 1.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

/* GET INVOLVED PAGE CSS */

#input {
  background: rgba(0, 0, 0, 0);
  border-radius: 15px;
  border-color: #ffe665;
  padding: 5px;
  width: max(33vw, 300px);
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: var(--text-color);
}

#formposition {
  margin-inline: auto;
  text-align: center;
}